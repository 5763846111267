/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { graphql } from "gatsby";
import { FAQs, Footer, Grid, Layout } from "~components";
import { useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

const FAQPage = ({ data, location }) => {
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  //

  const cms = data?.sanityFaqPage;

  //

  return (
    <>
      <Layout>
        <div
          css={[
            css`
              background: ${lowImpactMode
                ? colours.background
                : colours.foreground};
              color: ${lowImpactMode ? colours.foreground : colours.background};

              padding-top: 56px;

              ${MEDIA_QUERIES?.desktop} {
                padding-top: 90px;
              }
            `,
            tw`relative`
          ]}
        >
          <Grid node="section">
            <article
              css={[
                tw`col-span-12 md:col-span-12 relative block pt-12 md:pt-24 md:pb-24`
              ]}
            >
              <header css={[tw`relative mb-12`]}>
                <h1 css={[tw`text-h3 md:text-h2-md font-head text-center`]}>
                  {cms?.heading}
                </h1>
              </header>

              <FAQs questions={cms?.questions} />
            </article>
          </Grid>
        </div>

        <Footer />
      </Layout>
    </>
  );
};

export default FAQPage;

export const query = graphql`
  query FAQPage {
    sanityFaqPage {
      heading

      questions {
        question
        answer
      }

      seoTitle
      seoDescription
      seoKeywords
    }
  }
`;
